import React, { Component, Suspense } from 'react';
import { getData } from '../services';
import CartBtn from "../headerComponents/addcartBtn"
import RtCartBtn from "../restaurant/addcartBtn"
import CommonCartBtn from "../commonItem/addcartBtn"
import $ from "jquery";
let Header = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/header"));
export default class reorder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reOrderDetails: [],
      orderId: '',
      itemName: '',
      imageName: '',
      packName: '',
      mrp: '',
      unitPrice: '',
      quantity: '',
      mainCategory: '',
      orderNumber: ''
    }
  }
  componentDidMount() {
    $(".dashboardMenuLink").removeClass("dashboardActive");
    $("#myorderLink").addClass("dashboardActive");
    const { match: { params } } = this.props;
    let orderId = params.orderId
    if (window.sessionStorage.getItem('mainCategory') === '8') {
      const postData = {
        functionName: "userdashboarddetails/cmReOrder",
        orderId: orderId
      }
      getData(postData).then((data) => {
        if (data.data.success === "1") {
          this.setState({
            reOrderDetails: data.data.result.cmReOrder
          });
        }
      })
    }
    else if (window.sessionStorage.getItem('mainCategory') === '1') {
      const postData = {
        functionName: "userdashboarddetails/reOrder",
        orderId: orderId
      }
      getData(postData).then((data) => {
        if (data.data.success === "1") {
          this.setState({
            reOrderDetails: data.data.result.reOrder
          });
        }
      })
    }
    else if (window.sessionStorage.getItem('mainCategory') === '5') {
      const postData = {
        functionName: "userdashboarddetails/rtReOrder",
        orderId: orderId
      }
      getData(postData).then((data) => {
        if (data.data.success === "1") {
          this.setState({
            reOrderDetails: data.data.result.rtReOrder
          });
        }
      })
    }
  }
  componentWillReceiveProps() {
  }
  setId(itemId) {
    alert("setId...")
    var cmbSize = $("#cmbSize" + itemId).val().split('-');
    $("#hidStoreItemSizeId" + itemId).val(cmbSize[2]);
    if (cmbSize[3] > 0) {
      $("#customizeLink" + itemId).show();
      $("#customizeLinkAddCartBtnStatic" + itemId).show();
      $("#customizeLinkAddCartBtnDynamic" + itemId).hide();
    }
    else {
      $("#customizeLink" + itemId).hide();
      $("#customizeLinkAddCartBtnStatic" + itemId).hide();
      $("#customizeLinkAddCartBtnDynamic" + itemId).show();
    }
  }
  render() {
    let themeId = localStorage.getItem('themeId')
    let cartLabel = 'ADD TO CART'
    if (themeId === '36') {
      cartLabel = '+'
    }
    else if (themeId === '34' || themeId === '29' || themeId === '4') {
      cartLabel = '<span><img src="https://storedigfiles.s3.amazonaws.com/production/themes/cartBtnLokal.png" /> ADD TO CART</span>'
    }
    return (
      <div className={themeId === '29' ? "mraCoverWraper coverWraper" : (themeId === '28') ? "clContentWrap coverWraper" : "coverWraper"}>
        <Suspense fallback={<div></div>}>
          <Header />
          <div className="contentWrap">
            <div className="dashboardWrap"></div>
            <div>
              <div className="dashboardContent">
                <div className="accountdetails accountReorderDiv">
                  <div className="dashTitle">Reorder</div>
                  <div className="dashSection">
                    <div className="dashboardTable dashboardTableMain">
                      <table class="table table-bordered">
                        <thead>
                          <th>Item Description</th>
                          <th>MRP</th>
                          <th>Unit Price</th>
                          <th>Add to Cart</th>
                        </thead>
                        {this.state.reOrderDetails.length > 0 ?
                          <tbody>
                            {this.state.reOrderDetails.map((reOrderDetails, i) => (
                              <tr>
                                <td>
                                  <div class="reorder-td-img"><img src={reOrderDetails.imageName} width="60" alt=""></img></div>
                                  <div class="reorder-td-desc">
                                    <p class="reorder-td-desc-head">{reOrderDetails.itemName}</p>
                                    <p>{reOrderDetails.packName}</p>
                                  </div>
                                </td>
                                <td>Rs. {parseFloat(reOrderDetails.MRP).toFixed(window.sessionStorage.getItem('decimalCount'))}</td>
                                <td>Rs. {parseFloat(reOrderDetails.unitPrice).toFixed(window.sessionStorage.getItem('decimalCount'))}</td>
                                <td id="reorderTxt">
                                  <div>
                                    <input type="hidden" id={"hidPackIndex" + i} value={i} />
                                    <input type="hidden" id={"sisId" + i} value={reOrderDetails.sisId} />
                                    <input type="hidden" id={"itemId" + i} value={reOrderDetails.itemId} />
                                    <input type="hidden" id={"price" + i} value={reOrderDetails.unitPrice} />
                                    <input type="hidden" id={"unit" + i} value={reOrderDetails.unit} />
                                    <input type="hidden" id={"packId" + i} value={reOrderDetails.packId} />
                                    <input type="hidden" id={"itemStockQuantity" + i} value={reOrderDetails.stockQuantity} />
                                    <input type="hidden" id={"itemName" + i} value={reOrderDetails.itemName} />
                                    <input type="hidden" id={"mrp" + i} value={reOrderDetails.MRP} />
                                    <input type="hidden" id={"image" + i} value={reOrderDetails.imageName} />
                                  </div>
                                  <div className="productRestaurantPrice">
                                    {(() => {
                                      if (window.sessionStorage.getItem('mainCategory') === '5') {
                                        if (reOrderDetails.sizeGroup.length > 1) {
                                          return (
                                            <select style={{ display: 'none' }} id={"cmbSize" + reOrderDetails.itemId} onChange={(e) => this.setId(reOrderDetails.itemId)}>
                                              {reOrderDetails.sizeGroup.map((sizeName, sizeIndex) => (
                                                <option value={sizeName.itemsizname + '-' + sizeName.itemPrice + '-' + sizeName.storeItemSizeId + '-' + sizeName.toppingData.length}>{sizeName.itemsizname} - {reOrderDetails.currencySymbol} {parseFloat(sizeName.itemMrp).toFixed(window.sessionStorage.getItem('decimalCount'))}</option>
                                              ))}
                                            </select>
                                          )
                                        }
                                        else {
                                          return (
                                            <div>
                                              <span>{reOrderDetails.itemsizname} - {reOrderDetails.currencySymbol} {parseFloat(reOrderDetails.itemMrp).toFixed(window.sessionStorage.getItem('decimalCount'))}
                                              </span>
                                              {reOrderDetails.sizeGroup.map((sizeName, sizeIndex) => (
                                                <input type="hidden" id={"cmbSize" + reOrderDetails.itemId} value={sizeName.itemsizname + '-' + sizeName.itemPrice + '-' + sizeName.storeItemSizeId + '-' + sizeName.toppingData.length} />
                                              ))}
                                            </div>
                                          )
                                        }
                                      }
                                    })()}
                                    <input type="hidden" id={"hidStoreItemSizeId" + reOrderDetails.itemId} value={reOrderDetails.storeItemSizeId} ></input>
                                  </div>
                                  {(() => {
                                    if (window.sessionStorage.getItem('mainCategory') === '8') {
                                      if (reOrderDetails.stockQuantity > 0) {
                                        return (
                                          <CommonCartBtn
                                            cartItemFlag={reOrderDetails.isCart}
                                            itemId={reOrderDetails.itemId}
                                            itemName={reOrderDetails.itemName}
                                            storeId={reOrderDetails.storeId}
                                            itemTitle={reOrderDetails.itemTitle}
                                            brand={reOrderDetails.brandName}
                                            itemSize={reOrderDetails.sizeResult}
                                            netAmount={reOrderDetails.unitPrice}
                                            mrp={reOrderDetails.MRP}
                                            itemImage={reOrderDetails.imageName}
                                            index={reOrderDetails.itemId}
                                            itemMasterId={reOrderDetails.itemMasterId}
                                            itemAddedColour="itemAddedColour"
                                          ></CommonCartBtn>
                                        )
                                      }
                                      else {
                                        return (
                                          <div class="isOutOfStock">Out Of Stock</div>
                                        )
                                      }
                                    }
                                    else if (window.sessionStorage.getItem('mainCategory') === '1') {
                                      if (reOrderDetails.stockQuantity > 0) {
                                        return (
                                          <CartBtn index={i}
                                            item_ids={reOrderDetails.itemId}
                                            item_names={reOrderDetails.itemName}
                                            item_prices={reOrderDetails.unitPrice}
                                            store_item_stock_ids={reOrderDetails.sisId}
                                            pack_ids={reOrderDetails.packId}
                                            stock_quantity={reOrderDetails.stockQuantity}
                                            itemImage={reOrderDetails.imageName}
                                            itemAddedColour="itemAddedColour"
                                            qtyError="qtyError"
                                            itemCount="itemCount"
                                            fromPage="Profile"
                                            cartLabel={cartLabel}
                                          >
                                          </CartBtn>
                                        )
                                      }
                                      else {
                                        return (
                                          <div class="isOutOfStock">Out Of Stock</div>
                                        )
                                      }
                                    }
                                    else if (window.sessionStorage.getItem('mainCategory') === '5') {
                                      if (reOrderDetails.isTopping == true) {
                                        return (
                                          <RtCartBtn
                                            isMultiSize={reOrderDetails.sizeGroup.length}
                                            storeId={reOrderDetails.storeId}
                                            itemId={reOrderDetails.itemId}
                                            customize={reOrderDetails.isTopping}
                                            index={reOrderDetails.itemId + '-' + reOrderDetails.storeItemSizeId}
                                            itemName={reOrderDetails.itemName}
                                            itemSize={reOrderDetails.itemsizname}
                                            itemMrp={reOrderDetails.itemMrp}
                                            itemImage={reOrderDetails.imageName}
                                            cartLabel={cartLabel}
                                            storeItemSizeId={reOrderDetails.storeItemSizeId}
                                            itemAddedColour="itemAddedColour"
                                            qtyError="qtyError" itemCount="itemCount"></RtCartBtn>
                                        )
                                      }
                                      else {
                                        return (
                                          <RtCartBtn isMultiSize={reOrderDetails.sizeGroup.length}
                                            storeId={reOrderDetails.storeId}
                                            itemId={reOrderDetails.itemId}
                                            customize={reOrderDetails.isTopping}
                                            index={reOrderDetails.itemId + '-' + reOrderDetails.storeItemSizeId}
                                            itemName={reOrderDetails.itemName}
                                            itemSize={reOrderDetails.itemsizname}
                                            itemMrp={reOrderDetails.itemMrp}
                                            itemImage={reOrderDetails.imageName}
                                            storeItemSizeId={reOrderDetails.storeItemSizeId}
                                            cartLabel={cartLabel}
                                            itemAddedColour="itemAddedColour"
                                            qtyError="qtyError"
                                            itemCount="itemCount"></RtCartBtn>
                                        )
                                      }
                                    }
                                  })()}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          : <tbody><tr class='text-danger text-center'><td colspan="7">No data Found</td> </tr></tbody>}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Suspense>
      </div>
    )
  }
}
