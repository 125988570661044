import React, { Component, Suspense } from 'react'
import Arrow from "../images/lokal/ph_arrow-up.png"
import Grocery from "../images/lokal/Groceries-near.png"
import Store from "../images/lokal/store1.png"
import Timer from "../images/lokal/timer.png"

export default class Storelisting extends Component {

    render() {
        return (
            <div>
                <div className='top-header'>
                    <div className='container-fluid outer-padding'>
                        <div className="row m-0">
                            <div className='bread-crumb col-12'>
                                <span className='link'>Home</span>
                                <span className='px-1'>
                                    <img src={Arrow} alt="Arrow" className='img-responsive' width="15" height="15" />
                                </span>
                                <span className='link'>Mumbai (Saki Vihar)</span>
                                <span className='px-1'>
                                    <img src={Arrow} alt="Arrow" className='img-responsive' width="15" height="15" />
                                </span>
                                <span className='link-active'>Groceries</span>
                            </div>
                            <div className='grocery-near col-12'>
                                <div className="">
                                    <div className='store-listimg-bg'>
                                        <img src={Grocery} alt="Grocery" className='img-fluid' width="70" height="70" />
                                    </div>
                                </div>
                                <div className="">
                                    <div className='grocery-head'>Grocery Stores Near You</div>
                                    <div className='grocery-no'>
                                        143 Store
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='stores-near'>
                    <div className='container-fluid outer-padding'>
                        <div className="row m-0">
                            <div className="col-12 col-lg-6 col-md-6 mb-4">
                                <div className='store-list'>
                                    <div className='store-img'>
                                        <img src={Store} alt="store" className='img-fluid'
                                            width="150" height="150" />
                                    </div>
                                    <div className='store-deatils'>
                                        <div className='store-name'>
                                            Real Suvidha Dry Fruits
                                        </div>
                                        <div className='store-address'>
                                            Saki Vihar Road
                                        </div>
                                        <div className='store-position'>
                                            <div className="store-distance">
                                                2.8 km
                                            </div>
                                            <div className='store-time'>
                                                <span className='mr-2'><img src={Timer} alt="Timer" className='img-fluid' width="20" height="20" /></span>
                                                43 mins
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 col-md-6 mb-4">
                                <div className='store-list'>
                                    <div className='store-img'>
                                        <img src={Store} alt="store" className='img-fluid'
                                            width="150" height="150" />
                                    </div>
                                    <div className='store-deatils'>
                                        <div className='store-name'>
                                            Real Suvidha Dry Fruits
                                        </div>
                                        <div className='store-address'>
                                            Saki Vihar Road
                                        </div>
                                        <div className='store-position'>
                                            <div className="store-distance">
                                                2.8 km
                                            </div>
                                            <div className='store-time'>
                                                <span className='mr-2'><img src={Timer} alt="Timer" className='img-fluid' width="20" height="20" /></span>
                                                43 mins
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 col-md-6 mb-4">
                                <div className='store-list'>
                                    <div className='store-img'>
                                        <img src={Store} alt="store" className='img-fluid'
                                            width="150" height="150" />
                                    </div>
                                    <div className='store-deatils'>
                                        <div className='store-name'>
                                            Real Suvidha Dry Fruits
                                        </div>
                                        <div className='store-address'>
                                            Saki Vihar Road
                                        </div>
                                        <div className='store-position'>
                                            <div className="store-distance">
                                                2.8 km
                                            </div>
                                            <div className='store-time'>
                                                <span className='mr-2'><img src={Timer} alt="Timer" className='img-fluid' width="20" height="20" /></span>
                                                43 mins
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 col-md-6 mb-4">
                                <div className='store-list'>
                                    <div className='store-img'>
                                        <img src={Store} alt="store" className='img-fluid'
                                            width="150" height="150" />
                                    </div>
                                    <div className='store-deatils'>
                                        <div className='store-name'>
                                            Real Suvidha Dry Fruits
                                        </div>
                                        <div className='store-address'>
                                            Saki Vihar Road
                                        </div>
                                        <div className='store-position'>
                                            <div className="store-distance">
                                                2.8 km
                                            </div>
                                            <div className='store-time'>
                                                <span className='mr-2'><img src={Timer} alt="Timer" className='img-fluid' width="20" height="20" /></span>
                                                43 mins
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 col-md-6 mb-4">
                                <div className='store-list'>
                                    <div className='store-img'>
                                        <img src={Store} alt="store" className='img-fluid'
                                            width="150" height="150" />
                                    </div>
                                    <div className='store-deatils'>
                                        <div className='store-name'>
                                            Real Suvidha Dry Fruits
                                        </div>
                                        <div className='store-address'>
                                            Saki Vihar Road
                                        </div>
                                        <div className='store-position'>
                                            <div className="store-distance">
                                                2.8 km
                                            </div>
                                            <div className='store-time'>
                                                <span className='mr-2'><img src={Timer} alt="Timer" className='img-fluid' width="20" height="20" /></span>
                                                43 mins
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 col-md-6 mb-4">
                                <div className='store-list'>
                                    <div className='store-img'>
                                        <img src={Store} alt="store" className='img-fluid'
                                            width="150" height="150" />
                                    </div>
                                    <div className='store-deatils'>
                                        <div className='store-name'>
                                            Real Suvidha Dry Fruits
                                        </div>
                                        <div className='store-address'>
                                            Saki Vihar Road
                                        </div>
                                        <div className='store-position'>
                                            <div className="store-distance">
                                                2.8 km
                                            </div>
                                            <div className='store-time'>
                                                <span className='mr-2'><img src={Timer} alt="Timer" className='img-fluid' width="20" height="20" /></span>
                                                43 mins
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}