import React, { Component, Suspense } from 'react'
import List from "../luna/store-listing-list"
import Listcategory from "../luna/store-listing-category"

let Header = React.lazy(() => import("../" + "luna" + "/header"));
let Footer = React.lazy(() => import("../" + "luna" + "/footer"));

export default class Storelisting extends Component {

    render() {
        return (

            <div >
                <Suspense fallback={<div></div>}>
                    <Header></Header>
                    <List/>
                    <Listcategory/>
                    <Footer></Footer>
                </Suspense>
            </div>

        )
    }
}